<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table visit-report-table">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Supplier Name</th>
                        <th>Batch No</th>
                        <th>Purchase Date</th>
                        <th>Stock in hand</th>
                        <th>Exp Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">
                        <td>{{ item.product_name }}</td>
                        <td>{{ item.supplier_name }}</td>
                        <td>{{ item.batch_number }}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.stock_in_hand }}</td>
                        <td>{{ item.exp_date }}</td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
const $route = useRoute();
const $router = useRouter();
const props = defineProps({
    tableItems: {
        type: Array,
    }
})
</script>

<style scoped>
.visit-report-table > :not(caption) > * > * {
    padding: 1rem !important;
}

.visit-report-table .pl-10px {
    padding-left: 10px !important;
}
</style>